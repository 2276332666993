import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'
import { device } from './device'
import { Column1, GridWithGap } from './styled/grid'
const HeroImage = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1348px;
  height: 55vh;
  color: white;
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: 25px auto;
  margin-top: 0;

  h1 {
    margin-top: 6rem;
    max-width: 75ch;
    font-weight: 700;
    color: white;
    text-shadow: 1px 1px black;
    @media (max-width: 1024px) {
      margin-top: -5rem;
      font-size: 2rem;
    }
  }

  p,
  li {
    text-shadow: 1px 1px black;
    font-weight: 600;
    margin: 5px;

    @media (max-width: 1024px) {
      margin-top: 0rem;
    }
  }
`
const HeroTekElements = styled.div`
  @media only screen and ${device.tablet} {
    margin: 25px;
    margin-top: -4rem;
  }
  @media only screen and ${device.tabletMaxL} {
    margin: 25px;
  }
`
const Holder = styled.div`
  margin-top: 5rem;

  @media only screen and ${device.tablet} {
  }
`
const VideoHolder = styled.div`
  width: 25%;
  border-radius: 5px;
  position: absolute;
  min-width: 10%;
  min-height: 10%;
  top: 55%;
  left: 70%;
  transform: translate(-50%, -50%);

  @media (max-width: 1024px) {
    display: none;
  }

  @media only screen and ${device.tablet} {
    margin-left: -15px;
    margin-top: 1rem;
    margin-left: -5rem;
  }
`
const GridHolderTopChekMark = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-gap: 16px;
  font-size: 20px;
`
const Span = styled.span`
  color: white;
`
const P = styled.span`
  width: 300px;
`

const SubPageVideoHero = ({ children, title, description1, description2, description3, Video }) => {
  const data = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "verksted_bakgrunn.png" }) {
        childImageSharp {
          fluid(maxWidth: 1348) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage fluid={data.hero.childImageSharp.fluid}>
      <HeroImage>
        <Holder>
          <GridWithGap>
            <Column1>
              <HeroTekElements>
                <h1>{title}</h1>
                {description1 && <p>{description1}</p>}

                {description2 && <p>{description2}</p>}

                {description3 && (
                  <p>
                    {
                      <GridHolderTopChekMark>
                        <P>
                          <Span>✔</Span> Godkjent elbil-verksted
                        </P>
                        <P>
                          <Span>✔</Span> Ivaretar nybilgaranti
                        </P>
                        <P>
                          <Span>✔</Span> 5 års garanti på de fleste reservedeler
                        </P>
                      </GridHolderTopChekMark>
                    }
                  </p>
                )}

                {children}
              </HeroTekElements>
            </Column1>
            <Column1>
              <VideoHolder>
                <video
                  src={Video}
                  controls={true}
                  autoPlay={true}
                  muted={true}
                  width="500px"
                  playsinline={false}
                  style={{ borderRadius: '5px' }}
                />
              </VideoHolder>
            </Column1>
          </GridWithGap>
        </Holder>
      </HeroImage>
    </BackgroundImage>
  )
}

export default SubPageVideoHero
