import React from 'react'
import PropTypes from 'prop-types'
import './layout.css'

import Footer from '../components/footer'
import { useGetFavoriteWorkshop } from '../hooks/use-favorite-workshop'
import ScrollTop from '../components/scrollTop'
import SubPageHeader from '../components/subPageHeader'
import { Layout } from '../components/styled/layout'

const SubPageLayoutNoHero = ({ children, onDropDownPageSelectionChange }) => {
  const workshop = useGetFavoriteWorkshop()

  return (
    <Layout>
      <SubPageHeader
        workshop={workshop}
        onDropDownPageSelectionChange={onDropDownPageSelectionChange}
      />

      <main>{children}</main>
      <ScrollTop />
      <br></br>
      <Footer />
    </Layout>
  )
}

SubPageLayoutNoHero.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SubPageLayoutNoHero
