import styled from 'styled-components'
import { device } from '../device'

export const FlexSpacer = styled.div`
  flex: 1;
`

export const FlexSpacerMUp = styled.div`
  @media ${device.laptopL} {
    flex: 1;
  }
`

export const Grid = styled.div`
  display: flex;
  align-items: center;
`

export const Column1 = styled.div`
  flex: 1;
`

export const Column2 = styled.div`
  flex: 2;
`

export const Column3 = styled.div`
  flex: 3;
`

export const GridWithGap = styled.div`
  ${Column1}:not(:first-child), ${Column2}:not(:first-child), ${Column3}:not(:first-child) {
    margin-top: 2rem;
  }
    
  ${Column1}:not(:first-child):not(:first-child):not(:last-child), ${Column2}:not(:first-child):not(:first-child):not(:last-child), ${Column3}:not(:first-child):not(:first-child):not(:last-child) {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  @media ${device.laptop} {
    display: flex;
    
    ${Column1}:first-child, ${Column2}:first-child, ${Column3}:first-child {
      margin-left: 0;
      margin-right: 1rem;
    }
    
    ${Column1}:last-child, ${Column2}:last-child, ${Column3}:last-child {
      margin-left: 1rem;
      margin-right: 0;
    }
    
    ${Column1}:not(:first-child), ${Column2}:not(:first-child), ${Column3}:not(:first-child) {
      margin-top: 0;
    }
  }
`

export const Container = styled.div`
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
  max-width: 1348px;
  display: flex;
  align-items: center;
  height: 50%;

  @media ${device.laptopL} {
    padding-left: 0;
    padding-right: 0;
  }
`
