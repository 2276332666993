import React from 'react'
import SEO from '../../components/seo'
import SubPageLayoutNoHero from '../../layouts/subPageLayoutNoHero'
import styled from 'styled-components'
import { Section } from '../../components/styled/section'
import { Column1, GridWithGap } from '../../components/styled/grid'
import BremseSkift from '../../images/clutchogbrems_small.png'
import SubPageVideoHero from '../../components/subPageVideoHero'
import { Layout } from '../../components/styled/layout'
import ChooseRegion from '../../components/small_web_comp/ChooseRegion'
const PageSection = styled(Section)`
  display: flex;
  align-items: center;
  margin: 15px auto;
`
const Text = styled.div`
  flex: 1;
  margin-top: -3rem;
  h4 {
    border-bottom: 2px solid #dddddd;
  }
`

const CB = () => {
  const title = 'Clutch og brems'
  const description1 =
    'Hos BilXtra Verksted opererer vi utelukkende med bildeler av OE-kvalitet eller Matching Quality.'
  const description2 =
    'Det vil si at alle våre deler er av minst like god kvalitet som originalmontert deler. Lar du oss fikse bilen din, kan du derfor være trygg på at du får topp kvalitet!'
  const Video = require('../../images/video/brems-verksted.mp4')
  return (
    <Layout>
      <SubPageLayoutNoHero title={title}>
        <SEO title={title} />
        <SubPageVideoHero
          title={title}
          description1={description1}
          description2={description2}
          Video={Video}
        ></SubPageVideoHero>
        <PageSection>
          <GridWithGap>
            <Column1>
              <Text>
                <h4>Clutch og bremseskift</h4>
                <p>
                  BilXtra verkstedene utfører bremsetester på bremseprøver (ruller). Er ikke
                  bremsene innenfor kravene kan vi reparere og skifte bremsedeler. Ta gjerne en
                  bremseservice for å være på den sikre siden.
                </p>
                <p>
                  Dessuten er det økonomisk og drivstoffbesparende med bremser som ikke «ligger på».
                  Om vinteren kan saltlaken på veiene tære ekstra på bremsedelene, noe som gjør at
                  Caliperne ikke glir skikkelig.
                </p>
                <p>
                  Bytte av clutch gjøres selvfølgelig også på våre verksteder - uansett om det er en
                  mer moderne clutch med dobbelmasse svinghjul, eller en god gammeldags clutch.{' '}
                </p>
                <ChooseRegion />
              </Text>
            </Column1>
            <Column1>
              <img src={BremseSkift} width="100%" alt="Fix. Bremser" />
            </Column1>
          </GridWithGap>
        </PageSection>
      </SubPageLayoutNoHero>
    </Layout>
  )
}

export default CB
